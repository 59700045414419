@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Allura&family=Architects+Daughter&family=Great+Vibes&family=Lobster&family=Merriweather+Sans&family=Nunito+Sans:ital@1&display=swap');




:root {
  --pink: #A92239;
  --lightpurple: #8E87B3;
  --offwhite:  #f4eae9;
  --darkpurple: #252330;
  --gray-a:  rgba(108, 119, 137, 0.55);
  --gray: #6b7688;

  --font-editorial: 'Allura';
  --font-text: 'Merriweather Sans';

}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-text);
}


ul, li {
  list-style: none;
}

a {
  text-decoration: none;
}

body {
  background-color: var(--offwhite);
}

input,
textarea,
button,
a,
p,
div {
  font-family: var(--font-text);
}

input,
textarea {
  border: 1px solid var(--lightpurple);
  outline: none;
}

input:focus,
textarea:focus {
  border: 1px solid rgb(74, 0, 123);

}