.signup {
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        width: 40%;
        h1 {
            font-size: 1.5rem;
            color: var(--pink);
            text-align: center;
        }

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            li {
                width: 100%;
                input {
                    padding: 0.3rem;
                    border-radius: 8px;
                    padding-left: 0.5rem;
                    width: 100%;
                }

                
            }

            .btns-signup {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                button {
                    color: var(--lightpurple);
                    font-size: 1.1rem;
                    transition: 0.4s ease-in-out;
                    &:hover {
                        color: var(--pink);
                    }
                 
                }
            }

            .btn-redirect-login {
                a {
                    color: var(--lightpurple);
                    font-size: 1.05rem;
                    padding-left: 5px;
                    transition: 0.4s ease-in-out;
                    &:hover {
                        color: var(--pink);
                    }
                    @media screen and (max-width: 325px) {
                        display: block;
                        text-align: center;
                    }
                }
               
            }
        }

        @media screen and (max-width: 600px) {
          width: 75%;
        }
    }
}