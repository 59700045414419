.account-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;  
    height: 100%;

        .account-name {
            color: var(--pink);
            font-weight: bold;
            font-size: 1.2rem;
            padding-top: 9rem;

            @media screen and (max-width: 800px) {
                padding-top: 12rem;

            }
            @media screen and (max-width: 600px) {
                padding-top: 15rem;

            }
            @media screen and (max-width: 415px) {
                padding-top: 15rem;

            }
            @media screen and (max-width: 400px) {
                padding-top: 8rem;

            }
            @media screen and (max-width: 320px) {
                padding-top: 3rem;

            }

    
        }
    
        .account-username {
            color: var(--pink);
            font-size: 1.1rem;
    
        }

        .btns-account {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 1rem;
            width: 20%;

            button {
                font-size: 1rem;
                margin: 5px;
                color: var(--darkpurple);
                border: 2px solid var(--lightpurple);
                padding: 2px 5px;
                border-radius: 8px;
                transition: 0.4s ease-in-out;
                width: 100%;

                &:hover {
                    background-color: var(--lightpurple);
                    color: white;
                }
            }
            @media screen and (max-width: 800px) {
                width: 50%;

            }
            @media screen and (max-width: 400px) {
                width: 70%;

            }
            @media screen and (max-width: 280px) {
                width: 90%;

            }
        }
    
        .account-posts-section {
            width: 75%;
    
            .account-posts-title {
                font-size: 1.25rem;
                font-weight: bold;
                text-align: center;
                margin: 1.5rem;
                color: var(--lightpurple);
            }
    
            .account-posts {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1.5rem;
                width: 100%;

                .post {
                    border: 3px solid var(--lightpurple);
                    border-radius: 10px;
                    width: 100%;
                    padding: 0;
                    margin-bottom: 10px;
                
                    .post-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;


                        a {
                            width: 100%;
                            display: flex;
                            align-items: flex-start;
                            justify-content: center;
                            flex-direction: column;
                            gap: 1rem;

                            h3 {
                                font-weight: 900;
                                color: var(--lightpurple);
                            }
                        }

                        .btns-post {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            flex-direction: row;
                            gap: 1rem;
                            padding-left: 2rem;
                            padding-bottom: 1rem;

                            button {
                                font-size: 1rem;
                                margin: 5px;
                                color: var(--darkpurple);
                                border: 2px solid var(--lightpurple);
                                padding: 2px 5px;
                                border-radius: 8px;
                                transition: 0.4s ease-in-out;
                                @media screen and (max-width: 320px) {
                                    font-size: 0.8rem;
                                    margin: 2px;
                                    padding: 2px 3px;

                                }
                             

                                &:hover {
                                    background-color: var(--lightpurple);
                                    color: white;
                
                                }
                            } 
                            @media screen and (max-width: 360px) {
                                flex-direction: column;
                                padding: 5px;

                            }
                         
                        }
                        @media screen and (max-width: 450px) {
                            justify-content: center;

                        }
                    }
                }   
            }

            @media screen and (max-width: 1050px) {
                margin-bottom: 45rem;
            }
            @media screen and (max-width: 1000px) {
                margin-bottom: 350px;
            }
            @media screen and (max-width: 800px) {
                margin-bottom: 450px;
            }
            @media screen and (max-width: 600px) {
                margin-bottom: 250px;
            }
            @media screen and (max-width: 450px) {
                margin-bottom: 200px;
            }
        }
}