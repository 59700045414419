.header {
    position: fixed;
    z-index: 2;
    width: 100%;
    background: var(--lightpurple);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
        font-family: var(--font-editorial);
        font-size: 4.5rem;
        text-align: center;

        @media screen and (max-width: 700px) {
            font-size: 4rem;
        }
        @media screen and (max-width: 600px) {
            font-size: 3.5rem;
            width: 60%;
            padding-bottom: 10px;
        }
        @media screen and (max-width: 520px) {
            font-size: 3rem;
        }
        @media screen and (max-width: 390px) {
           width: 80%;
        }
        @media screen and (max-width: 295px) {
           width: 95%;
        }

    }

   .hamburger{
    display: none;
    @media screen and (max-width: 570px) {
        display: flex;
    }
   }

}