.delete-account-page {

    width: 20%;
            
    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        width: 100%;

        @media screen and (max-width: 1050px) {
            margin-top: -800px;
        }
        @media screen and (max-width: 1000px) {
            margin-top: -100px;
        }

        @media screen and (max-width: 800px) {
            margin-top: -380px;
        }
        @media screen and (max-width: 720px) {
            margin-top: -500px;
        }
        @media screen and (max-width: 600px) {
            margin-top: -200px;
        }
    
        @media screen and (max-width: 450px) {
            margin-top: 0px;
        }
     
        @media screen and (max-width: 320px) {
            margin-top: -150px;
        }



    li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 1rem;

        .delete-account-title {
            font-size: 1.2rem;
            color: var(--pink);
            text-align: center;
            margin-bottom: 20px;
    
         
            @media screen and (max-width: 450px) {
                margin-top: 0px;
            }
            @media screen and (max-width: 325px) {
                margin-top: 90px;
            }
        }

        .btn-delete-account {

            font-size: 1rem;
                        margin: 5px;
                        color: var(--darkpurple);
                        border: 2px solid var(--lightpurple);
                        padding: 2px 5px;
                        border-radius: 8px;
                        transition: 0.4s ease-in-out;
        
                        &:hover {
                            background-color: var(--lightpurple);
                            color: white;
                        }
    
        }

        

    }
    }

    @media screen and (max-width: 800px) {
        width: 80%;
    }
    @media screen and (max-width: 450px) {
        width: 80%;
    }


   
}