.logout-page {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        margin-top: -300px;
        font-size: 1.2rem;
        width: 90%;
        text-align: center;

        @media screen and (max-width: 1030px) {
            margin-top: -800px;

        }
        @media screen and (max-width: 780px) {
            margin-top: -500px;

        }
        @media screen and (max-width: 600px) {
            margin-top: -350px;

        }
        @media screen and (max-width: 450px) {
            margin-top: -150px;

        }


    }
}