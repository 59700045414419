.home {
    height: 100vh;
    width: 100vw;
    background: url('./../../assets/images/broken_hearts.jpg');
    background-size: 28.4%;
    background-position-y: 102%;
    background-position-x: 50% ;
    background-color: var(--darkpurple);
    display: flex;
    align-items: center;
    justify-content: center;
  
    @media screen and (max-width: 1650px) {
        background-position-y: 98%;
        background-size: 52%;
    }
    @media screen and (max-width: 1050px) {
        background-position-y: 98%;
        background-size: 60%;
    }
    @media screen and (max-width: 1000px) {
        background-position-y: 98%;
    }
    @media screen and (max-width: 800px) {
        background-size: 60%;
        background-position-y: 90%;
    }
    @media screen and (max-width: 500px) {
        background-size: 60%;
        background-position-y: 105%;
    }
    @media screen and (max-width: 400px) {
        background-size: 60%;
        background-position-y: 99%;
    }

    .home-img {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1050px) {
            margin-top: -30rem;
        }
        @media screen and (max-width: 1000px) {
            margin-top: -13rem;
        }
        @media screen and (max-width: 800px) {
            margin-top: -13rem;
        }
        @media screen and (max-width: 700px) {
            margin-top: 0rem;
        }

        img {
            width: 60%;
            border-radius: 15px;
            box-shadow: 0 0 10px black;
      
            @media screen and (max-width: 1250px) {
                width: 75%;   
            }
            @media screen and (max-width: 800px) {
                width: 80%;
            }
            @media screen and (max-width: 600px) {
                width: 90%;
            }
        }
    }
}