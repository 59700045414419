.update-password-page {

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    
        form {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
    
            .update-password-title {
                font-size: 1.2rem;
                color: var(--pink);
                text-align: center;
                margin-bottom: 20px;
    
                @media screen and (max-width: 450px) {
                    padding-top: 8rem;
                 }
                 @media screen and (max-width: 415px) {
                    padding-top: 15rem;
                 }
                 @media screen and (max-width: 400px) {
                    padding-top: 8rem;
                 }
                 @media screen and (max-width: 325px) {
                     padding-top: 4rem;
                 }
            }
    
            width: 20%;
            
            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                width: 100%;

                @media screen and (max-width: 400px) {
                    width: 95%;
                 }
                 @media screen and (max-width: 320px) {
                    width: 90%;
                 }
                 
    
            li {
                width: 100%;
                input, textarea {
                    padding: 0.3rem;
                    border-radius: 8px;
                    padding-left: 0.5rem;
                    width: 100%;
                }
    
                input {
                    margin-bottom: 10px;
                }
    
                
            }
    
            .btns-update-password {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                gap: 1rem;
    
                 button {
                    font-size: 1rem;
                    margin: 5px;
                    color: var(--darkpurple);
                    border: 2px solid var(--lightpurple);
                    padding: 2px 5px;
                    border-radius: 8px;
                    transition: 0.4s ease-in-out;
    
                    &:hover {
                        background-color: var(--lightpurple);
                        color: white;
                    }
                }
    
                @media screen and (max-width: 450px) {
                    flex-direction: column;
                    gap: 0.5rem;
                    margin-bottom: 12rem;
                }
    
            }
          }
    
            @media screen and (max-width: 800px) {
                width: 80%;
            }
            @media screen and (max-width: 450px) {
                width: 80%;
            }
        }
    
    

}