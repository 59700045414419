.app {
    background-color: var(--offwhite);
    width: 100%;
    height: 100vh;

    .app-container {

        padding-top: 5rem;

        @media screen and (max-width: 800px) {
            padding-top: 0;
        }
        @media screen and (max-width: 700px) {
            padding-top: 5rem;
        }
        @media screen and (max-width: 600px) {
            padding-top: 0.5rem;
        }
        @media screen and (max-width: 500px) {
            padding-top: 1.5rem;
        }
        @media screen and (max-width: 400px) {
            padding-top: 4rem;
        }
    }
}

.nav-section {
    min-height: 100vh;
    width: 100vw;
    background-color: var(--offwhite);
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    form {
        margin-top: -150px;
    }

    @media screen and (max-width: 1450px) {
        padding-top: 0;
        form {
            margin-top: 0px;
        }
    }
    @media screen and (max-width: 1050px) {
        form {
            margin-top: -500px;
        }
    }
    @media screen and (max-width: 800px) {
        form {
            margin-top: -300px;
        }
    }
    @media screen and (max-width: 600px) {
        padding-top: 5%;
        form {
            margin-top: 0;
        }
    }
    @media screen and (max-width: 400px) {
        padding-top: 25%;
    }
    @media screen and (max-width: 320px) {
        padding-top: 55%;
    }
}